import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import SearchLocation from './SearchLocation';
import { isOriginInUse } from '../../../util/search';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useConfiguration } from '../../../context/configurationContext';
import { parse } from '../../../util/urlHelpers';
import TopbarSearchForm from '../../TopbarContainer/Topbar/SearchPanelSearchForm/TopbarSearchForm';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    currentSearchParams,
    location,
    isProductSearchPage
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();

  const { keywords, address, origin, bounds } = parse(location?.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const topbarSearcInitialValues = () => {

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
        : null,
    };
  };
  const initialSearchFormValues = topbarSearcInitialValues();

  const handleSubmit = (values) => {

    const topbarSearchParams = () => {

      if (isProductSearchPage) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString(isProductSearchPage ? 'ProductSearchPage' : 'SearchPage', routeConfiguration, {}, searchParams));
  }



  const resetFilter = () => {
    history.push(createResourceLocatorString(isProductSearchPage ? 'ProductSearchPage' : 'SearchPage', routeConfiguration, {}, {}));
  }

  return (
    <div className={classes}>
      <div className={isProductSearchPage ? css.searchProduct : css.searchOptions}>
        {searchInProgress ? (
          <FormattedMessage id="MainPanelHeader.loadingResults" />
        ) :
          isProductSearchPage ? (
            // <FormattedMessage
            //   id="MainPanelHeader.foundResults"
            //   values={{ count: resultsCount }}
            // />
            <div className={css.exclusiveShop}>
              <p className={css.shopTitle}>
                <FormattedMessage
                  id="MainPanelHeader.shop"
                />
              </p>
              <p className={css.shopSubTitle}>
                <FormattedMessage
                  id="MainPanelHeader.discoverMember"
                />
              </p>
            </div>
          ) :
            (
              <div>
                <p className={css.title}>
                  <FormattedMessage
                    id="MainPanelHeader.title"
                  />
                </p>
                <p className={css.subTitle}> <FormattedMessage
                  id="MainPanelHeader.subTitle"
                  values={{ firstWeek: <b>Saturdays</b>, secondWeek: <b>Sundays</b> }}
                /></p>
              </div>
            )}
        {isProductSearchPage ?
          <div className={css.productSearchForm}>
            <TopbarSearchForm
              className={css.searchBox}
              onSubmit={handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
              isProductSearchPage={isProductSearchPage}
            />
          </div>
          :
          <div className={css.searchForm}>
            <TopbarSearchForm
              className={css.searchBox}
              onSubmit={handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
            />
          </div>
        }
      </div>
      <div className={isProductSearchPage ? css.tabsWrapper : css.productTabWrapper}>
        <div className={css.leftWrapper}>
          {isProductSearchPage && <div className={css.productButton}>
            <button>All Products</button>
          </div>}
          {isProductSearchPage ?
            <div className={css.resetButtonLink} >
              <span onClick={() => resetFilter()}>
                Reset Filter
              </span>
            </div> :
            <div className={css.resetButton} >
              <span onClick={() => resetFilter()}>
                Reset Filter
              </span>
            </div>
          }
        </div>
        {isSortByActive && isProductSearchPage ? (
          <div className={css.sortyByWrapper}>
            {sortByComponent}
          </div>
        ) : null}
      </div>
      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
